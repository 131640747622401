/**
 * @author  XuHongli
 * @date  2022/12/29 15:49
 * @version 1.0
 * @description
 */

const InvitePart2_Data = [
  {
    title: '50万注册资金，一年以上注册时间',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Invite/v4_qualified_bg1.png'
  },
  {
    title: '工商营业执照——通过最新年检',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Invite/v4_qualified_bg2.png'
  },
  {
    title: '税务登记证——国税+地税',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Invite/v4_qualified_bg3.png'
  },
  {
    title: '组织机构代码证',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Invite/v4_qualified_bg4.png'
  },
  {
    title: '商标注册证——商标正在申请品牌提供《商标注册申请书》',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Invite/v4_qualified_bg5.png'
  },
  {
    title: '品牌销售授权证明——注册人与授权人的关系证明',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Invite/v4_qualified_bg6.png'
  }
]

export default InvitePart2_Data
