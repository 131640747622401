<template>
  <div class="InvitePart2">
    <div class="coverage_top">商家资质</div>
    <div class="qualified_content">
      <div
        v-for="(item, index) in InvitePart2_Data"
        :key="index"
        :data-wow-delay="(index + 1) * 0.1 + 's'"
        class="qualified_li wow qualified_li wow animate__fadeInUp"
      >
        <img :src="item.imgUrl" alt="" />
        <div class="headline">
          <span>{{ item.title }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WOW from "wowjs";
import InvitePart2_Data from "@/views/Invite/Data/InvitePart2_Data";

/**
 * @author  XuHongli
 * @date  2022/12/29 15:53
 * @version 1.0
 * @description
 */
export default {
  name: "InvitePart2",
  data() {
    return {
      InvitePart2_Data,
    };
  },
  mounted() {
    new WOW.WOW().init();
  },
};
</script>

<style scoped lang="scss">
.InvitePart2 {
  width: 100%;
  padding: 60px 0;
  position: relative;
  overflow: hidden;
  background-color: #f6f8fa;
  .coverage_top {
    font-size: 40px;
    font-family: Microsoft YaHei, serif;
    color: #222;
    text-align: center;
  }
  .qualified_content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 1470px;
    height: 560px;
    padding: 0 75px;
    margin: 30px auto 0;
    .qualified_li {
      width: 400px;
      height: 188px;
      margin-right: 40px;
      position: relative;
      &:hover {
        .headline {
          bottom: -20px;
          span {
            font-weight: 600;
            color: #fc0017;
          }
        }
      }
      img {
        width: 100%;
        height: 100%;
      }
      .headline {
        position: absolute;
        left: 26px;
        bottom: -35px;
        width: 348px;
        height: 75px;
        box-shadow: 0 0 35px 0 rgba(92, 92, 92, 0.23);
        border-radius: 1px;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        transition: all 0.4s;
        span {
          transition: all 0.4s;
          text-align: center;
          color: #222;
          font-size: 20px;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
