<template>
  <div class="invite">
    <BannerImg :img-url="$StaticFileUrl + '/mp/BannerImage/zsrz03.jpg'">
      <div class="title wow fadeInDown" data-wow-duration="1s">万鲸供应商合作招募</div>
      <div class="desc wow fadeInDown" data-wow-duration="1s">欢迎商家将好商品和好品牌推荐给万鲸平台， 为企业提供更优质商<br>品和服务</div>
      <div class="flex-row flex-col-center">
        <div class="btn-red wow flipInX" data-wow-duration="1s" style="margin-top: 100px">我要入驻</div>
      </div>
    </BannerImg>
    <InvitePart1></InvitePart1>
    <InvitePart2></InvitePart2>

    <div class="bottom_box" :style="{ backgroundImage: `url(${$StaticFileUrl + '/mp/BannerImage/v4_invite_footer_02.png'})` }">
      <div class="content">
        <div class="headline wow animate__fadeInDown" data-wow-delay="0.5s">如果你想要了解更多，请联系我们</div>
        <div class="subtitle wow animate__fadeInDown" data-wow-delay="1s">
          <img :src="$StaticFileUrl + '/mp/BannerImage/v4_invite_footer_phone.png'" alt="">
          <span>0755-83899969</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/12/28 17:38
 * @version 1.0
 * @description
 */
import BannerImg from '@/components/BannerImg'
import InvitePart1 from '@/views/Invite/InvitePart1'
import InvitePart2 from '@/views/Invite/InvitePart2'
import WOW from 'wowjs'
export default {
  name: 'Invite',
  components: {
    InvitePart2,
    InvitePart1,
    BannerImg,
  },
  mounted() {
    new WOW.WOW().init()
  }
}
</script>

<style scoped lang="scss">
.bottom_box{
  position: relative;
  height: 385px;
  background-repeat: no-repeat;
  background-size: cover;
  .content{
    position: absolute;
    top: 127px;
    left: 50%;
    transform: translateX(-50%);
    width: 600px;
    text-align: center;
    font-size: 40px;
    .headline{
      color: #333;
    }
    .subtitle{
      font-weight: 500;
      color: #fc0017;
      margin-top: 24px;
      img{
        margin-bottom: -8px;
        width: 44px;
        height: 44px;
      }
    }
  }
}
</style>
