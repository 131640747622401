<template>
  <div class="InvitePart1">
    <div class="coverage_top">入驻流程</div>
    <div class="flow_content">
      <div
        v-for="(item) of 6"
        :key="item"
        class="flow_li wow animate__fadeIn"
        :data-wow-delay="item * 0.5 + 's'">
        <div class="num_img">
          <img :src="$StaticFileUrl + `/mp/pages/Invite/v4_flow_num${item}.png`" alt="">
        </div>
        <div class="horizontal_line"></div>
        <div class="vertical_line"></div>
        <div class="bg_img">
          <img :src="$StaticFileUrl + `/mp/pages/Invite/v4_flow_bg${item}.png`" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WOW from 'wowjs'

/**
 * @author  XuHongli
 * @date  2022/12/29 15:35
 * @version 1.0
 * @description
 */
export default {
  name: 'InvitePart1',
  mounted() {
    new WOW.WOW().init()
  }
}
</script>

<style scoped lang="scss">
.InvitePart1{
  width: 100%;
  padding: 60px 0;
  overflow: hidden;
  position: relative;
  .coverage_top{
    font-size: 40px;
    font-family: Microsoft YaHei,serif;
    color: #222;
    text-align: center;
  }
  .flow_content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1470px;
    height: 426px;
    margin: 100px auto 0;
  }
  .flow_li{
    width: 245px;
    height: 426px;
    position: relative;
    &:last-child{
      .horizontal_line{
        width: 0;
        border: none;
      }
    }
    .num_img{
      position: absolute;
      width: 60px;
      left: 84px;
      top: -30px;
      height: 60px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .horizontal_line{
      left: 150px;
      width: 174px;
      position: absolute;
      border: 1px dotted #ffccd0;
    }
    .vertical_line{
      top: 35px;
      left: 115px;
      height: 150px;
      position: absolute;
      border: 1px dashed #979797;
    }
    .bg_img{
      position: absolute;
      width: 237px;
      height: 261px;
      left: 4px;
      bottom: 0;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
